<template>
  <ul class="main-nav" v-bind:class="$route.meta.headerMainNavClass">

    <li> <a> Home <router-link to="/"></router-link> </a> </li>

    <li class="login-link">
      <router-link to="/login">Login / Signup</router-link>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      isVisiblehome: false,
    };
  },
  methods: {
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
    homeMenu() {
      return (
        this.$route.name == "index"
      );
    },
  },
};
</script>
