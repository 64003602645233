import { createRouter, createWebHistory } from 'vue-router';
import axiosInstance from '@/services/axios';
import store from '@/store';

import Index from '@/views/frontend/pages/home/index.vue'
import ComingSoon from '@/views/frontend/pages/pages/coming-soon.vue'

import Error404 from '@/views/frontend/pages/pages/errorpages/error-404.vue'
import Error500 from '@/views/frontend/pages/pages/errorpages/error-500.vue'
import BlankPage from '@/views/frontend/pages/pages/blank-page.vue'

// Authentication
    // ** Doctor
import DoctorRegister from '@/views/frontend/pages/auth/doctor/doctor-register.vue'
import DoctorRegisterationCode from '@/views/frontend/pages/auth/doctor/doctor-registration-code.vue'
import DoctorRegisterstep1 from '@/views/frontend/pages/auth/doctor/doctor-register-step1.vue'
import DoctorRegisterstep2 from '@/views/frontend/pages/auth/doctor/doctor-register-step2.vue'
import DoctorRegisterstep3 from '@/views/frontend/pages/auth/doctor/doctor-register-step3.vue'
    // ** Patient
import Register from '@/views/frontend/pages/auth/register.vue'
import RegisterationCode from '@/views/frontend/pages/auth/registration-code.vue'
import PatientRegisterstep1 from '@/views/frontend/pages/auth/patient/patient-register-step1.vue'
import PatientRegisterstep2 from '@/views/frontend/pages/auth/patient/patient-register-step2.vue'
import PatientRegisterstep3 from '@/views/frontend/pages/auth/patient/patient-register-step3.vue'
import PatientRegisterstep4 from '@/views/frontend/pages/auth/patient/patient-register-step4.vue'
import PatientRegisterstep5 from '@/views/frontend/pages/auth/patient/patient-register-step5.vue'

import LoginPage from '@/views/frontend/pages/auth/login/login.vue'
import ForgotPassword from '@/views/frontend/pages/auth/login/forgot-password.vue'
import ResetPassword from '@/views/frontend/pages/auth/login/reset-password.vue'
import TwoFA from '@/views/frontend/pages/auth/login/twoFA.vue';

// Patient Dashboard
import Patient_Dashboard from '@/views/frontend/pages/patients/dashboard/patient-dashboard.vue'
import Profile_Settings from '@/views/frontend/pages/patients/profile-settings.vue'
import Change_Password from '@/views/frontend/pages/patients/change-password.vue'

//plans
import Patient_Plans from '@/views/frontend/pages/patients/plans/plans.vue'
import PatientMorningPlan from '@/views/frontend/pages/patients/plans/patient-morning-plan.vue'
// Items
import PlanItem from '@/views/frontend/pages/patients/items/plan-item.vue'

// Cart
import Carts from '@/views/frontend/pages/cart/index.vue'
import ProductCheckout from '@/views/frontend/pages/cart/product-checkout.vue'
import PaymentPreview from '@/views/frontend/pages/cart/payment-preview.vue'
import PaymentSuccess from '@/views/frontend/pages/cart/payment-success.vue'
import Orders from '@/views/frontend/pages/cart/orders.vue'
const routes = [

    /**************** Frontend  *************/
    {
        path: '/',
        name: 'login-page',
        component: LoginPage,
        redirect: '/login',
        meta: { requiresAuth: false }
    },
/*    {
        path: '/',
        name: 'ComingSoon',
        component: ComingSoon,
        redirect: '/coming-soon',
        meta: { requiresAuth: false }
    },*/
    {
        path: '/coming-soon',
        name: 'coming-soon',
        component: ComingSoon,
        meta: { requiresAuth: false }
    },
    {
        path: '/home',
        name: 'index',
        component: Index,
        meta: { requiresAuth: false }
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: Error404,
        meta: { requiresAuth: false }
    },
    {
        path: '/error-500',
        name: 'error-500',
        component: Error500,
        meta: { requiresAuth: false }
    },
    {
        path: '/blank-page',
        name: 'blank-page',
        component: BlankPage,
        meta: { requiresAuth: false }
    },

    // Authentication
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: { requiresAuth: false }
    },
    {
        path: '/registeration-code',
        name: 'registeration-code',
        component: RegisterationCode,
        meta: { requiresAuth: false }
    },
    {
        path: '/doctor-register',
        name: 'doctor-register',
        component: DoctorRegister
    },
    {
        path: '/doctor-registeration-code',
        name: 'doctor-registeration-code',
        component: DoctorRegisterationCode
    },
    {
        path: '/doctor-register-step1',
        name: 'doctor-register-step1',
        component: DoctorRegisterstep1
    },
    {
        path: '/doctor-register-step2',
        name: 'doctor-register-step2',
        component: DoctorRegisterstep2
    },
    {
        path: '/doctor-register-step3',
        name: 'doctor-register-step3',
        component: DoctorRegisterstep3
    },
    {
        path: '/patient-register-step1',
        name: 'patient-register-step1',
        component: PatientRegisterstep1
    },
    {
        path: '/patient-register-step2',
        name: 'patient-register-step2',
        component: PatientRegisterstep2
    },
    {
        path: '/patient-register-step3',
        name: 'patient-register-step3',
        component: PatientRegisterstep3
    },
    {
        path: '/patient-register-step4',
        name: 'patient-register-step4',
        component: PatientRegisterstep4
    },
    {
        path: '/register-step-two',
        name: 'patient-register-step5',
        component: PatientRegisterstep5,
        meta: { requiresAuth: false }

    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage,
        meta: { requiresAuth: false }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
        meta: { requiresAuth: false }
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: ResetPassword,
        meta: { requiresAuth: false }
    },
    {
        path: '/2fa',
        name: 'TwoFA',
        component: TwoFA,
        meta: { requiresAuth: false }
    },

    // Patient Dashboard
    {
        path: '/dashboard',
        name: 'patient-dashboard',
        component: Patient_Dashboard,
        meta: { requiresAuth: true }
    },
    {
        path: '/plans',
        name: 'patient-plans',
        component: Patient_Plans,
        meta: { requiresAuth: true }
    },
    {
        path: '/plans/:id',
        name: 'patient-morning-plan',
        component: PatientMorningPlan,
        meta: { requiresAuth: true }
    },
    {
        path: '/plan-item/:id',
        name: 'plan-item',
        component: PlanItem,
        meta: { requiresAuth: true }
    },
    {
        path: '/cart',
        name: 'carts',
        component: Carts,
        meta: { requiresAuth: true }
    },
    {
        path: '/profile-settings',
        name: 'profile-settings',
        component: Profile_Settings,
        meta: { requiresAuth: true }
    },
    {
        path: '/change-password',
        name: 'change-password',
        component: Change_Password
    },
    {
        path: '/product-checkout',
        name: 'product-checkout',
        component: ProductCheckout,
        meta: { requiresAuth: true }
    },
    {
        path: '/payment-preview',
        name: 'payment-preview',
        component: PaymentPreview,
        meta: { requiresAuth: true }
    },
    {
        path: '/payment-success',
        name: 'payment-success',
        component: PaymentSuccess,
        meta: { requiresAuth: true }
    },
    {
        path: '/orders',
        name: 'orders',
        component: Orders,
        meta: { requiresAuth: true }
    },



]


export const router = createRouter({
    history: createWebHistory('/'),
    linkActiveClass: 'active',
    routes,
});

router.beforeEach(async(to, from, next) => {
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const isAuthenticated = store.getters.isAuthenticated;
    const token = localStorage.getItem('token');
    console.log('isAuthenticated: ', isAuthenticated)
    console.log('token: ', token)

    // Check if the route does not require authentication (i.e., no meta field)
    if (!to.meta.requiresAuth) {
        console.log("to.meta.requiresAuth", !to.meta.requiresAuth)
        next(); // Proceed to the requested route
    } else if (!isAuthenticated && !token) {
        console.log("!isAuthenticated && !token", !isAuthenticated && !token)
        // Redirect to login if token is missing
        store.commit('setErrorMessage', 'You need to log in or register to access this content.');
        next('/login');
    }
   /* else if (to.path === '/login' && isAuthenticated) {
            next('/plans');
   }*/else {
        console.log("else")
        try {
            // Verify token by making a test request
            await axiosInstance.get('/api/user');
            next(); // Proceed to the requested route
        } catch (error) {
            // If token verification fails, remove token and redirect to home
            localStorage.removeItem('token');
            store.commit('setErrorMessage', 'Session expired. Please log in again.');
            localStorage.removeItem('token');
            store.commit('setIsAuthenticated', false);
            next('/');
        }
    }
});