<template>
  <div class="stickysidebar">
<!--  <div class="profile-sidebar patient-sidebar profile-sidebar-new">-->
  <div class="profile-sidebar patient-sidebar">
    <div class="widget-profile pro-widget-content">
      <div class="profile-info-widget">
        <router-link to="#" class="booking-doc-img">
          <img
            src="@/assets/img/patients/1.jpg"
            alt="User Image"
          />
        </router-link>
        <div class="profile-det-info">
          <h3><router-link to="#">Hendrita</router-link></h3>
          <div class="patient-details">
            <h5 class="mb-0">Patient ID : PT254654</h5>
          </div>
          <span
            >Female <i class="fa-solid fa-circle"></i> 32 years 03 Months</span
          >
        </div>
      </div>
    </div>
    <div class="dashboard-widget">
      <nav class="dashboard-menu">
        <ul>
          <li :class="{ active: isActive('/dashboard') }">
            <router-link to="/dashboard">
              <i class="fa-solid fa-shapes"></i>
              <span>Dashboard</span>
            </router-link>
          </li>
          <li :class="{ active: isActive(['/patient-plans-morning', '/patient-plans-lunch', '/patient-plans-evening']) }">
            <router-link :to="{ name: 'patient-plans'}">
              <i class="fa-solid fa-calendar-days"></i>
              <span>My Plans</span>
            </router-link>
          </li>
          <li :class="{ active: isActive('/profile-settings') }">
            <router-link to="/profile-settings">
              <i class="fa-solid fa-user-pen"></i>
              <span>Profile Settings</span>
            </router-link>
          </li>
          <li>
            <a href="#" @click="handleLogout">
              <i class="fa-solid fa-calendar-check"></i>
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
</template>

<script>
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { logoutUser } from '@/services/auth';

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();

    // Reactive state
    const activeClass = ref("active");

    // Logout function
    const handleLogout = async (event) => {
      event.preventDefault(); // Prevent the default anchor behavior
      try {
        await logoutUser();
        // Clear token from local storage
        localStorage.removeItem('token');
        // Navigate to login page after successful logout
        router.push('/login');
      } catch (error) {
        console.error('Error during logout:', error);
      }
    };

    // Method to check if the current route path is active
    const isActive = (paths) => {
      return paths.includes(route.path);
    };

    return {
      activeClass,
      handleLogout,
      isActive
    };
  }
};
</script>
