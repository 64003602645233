import axiosInstance from '@/services/axios';

// Create a new order with one or more items
export function createOrder(items) {
    console.log('itemms:', items);

    const response = axiosInstance.post('/api/order',
        { items: items});
    console.log( 'package_id:', items[0].package_id,' count:', items[0].count);
    console.log('created order itemm:', response.data);
    return response;
}

// Update an existing order with one or more items
export function updateOrder(orderId, items) {
    console.log('updated order itemms:', orderId, items);

    const response = axiosInstance.put(`/api/order/${orderId}`, { items });
    // console.log( 'package_id:', items[0].package_id,' count:', items[0].count);
    console.log('updated order itemm:', response.data);
    return response;
}

// Delete an existing order by its ID
export function deleteOrder(orderId) {
    return axiosInstance.delete(`/api/order/${orderId}`);
}

// Fetch a list of orders with pagination
export function getOrders(page, count) {
    const response = axiosInstance.get('/api/order', { params: { page, count } });
    console.log('Fetched order itemm:', response.data);
    return response
}

// checkout the order
export function checkoutPayment(orderId) {
    const response = axiosInstance.get(`/api/order/transaction/${orderId}`);
    console.log('Fetched order itemm:', response.data);
    return response;
}