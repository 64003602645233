<template>
    <!-- Page Content -->
    <div class="content login-page pt-0">
      <div class="container-fluid">
        <!-- Register Content -->
        <div class="account-content">
          <div class="row align-items-center">
            <div class="login-right">
              <div class="inner-right-login">
                <div class="login-header">
                  <div class="logo-icon">
                    <img src="../../../../../assets/img/logo.svg" alt="doccure-logo" />
                  </div>
                  <div class="step-list">
                    <ul>
                      <li><a href="javascript:void(0)" class="active-done">1</a></li>
                      <li><a href="javascript:void(0)" class="active-done">2</a></li>
                      <li><a href="javascript:void(0)" class="active-done">3</a></li>
                      <li><a href="javascript:void(0)" class="active">4</a></li>
                      <li><a href="javascript:void(0)">5</a></li>
                      <li><a href="javascript:void(0)">6</a></li>
                    </ul>
                  </div>
                  <form  @submit.prevent="submitForm">

                    <div class="mb-3 form-focus"
                         :class="{ 'passwordStrengthMessageIsMeet': passwordStrengthMessage }"
                    >
                      <input
                          type="password"
                          class="form-control floating"
                          v-model="password"
                          @input="checkPasswordStrength"
                          required
                      />
                      <label class="focus-label">Create Password</label>
                      <div v-if="passwordError" class="text-danger">{{ passwordError }}</div>
                      <div v-if="passwordStrengthMessage" class="text-info">{{ passwordStrengthMessage }}</div>
                      <div v-if="passwordStrength" class="progress mt-2">
                        <div
                            class="progress-bar"
                            :class="passwordStrength"
                            role="progressbar"
                            :style="{ width: passwordStrengthPercent + '%' }"
                        ></div>
                      </div>
                    </div>
                    <div class="mb-3 form-focus">
                      <input
                          type="password"
                          class="form-control floating"
                          v-model="confirmPassword"
                          required
                      />
                      <label class="focus-label">Confirm Password</label>
                      <div v-if="passwordMismatchError" class="text-danger">Passwords do not match.</div>
                    </div>

                    <div class="mt-5">
                      <b-button
                          class="btn btn-primary w-100 btn-lg login-btn"
                          type="submit"
                      >
                        continue
                      </b-button>
                    </div>
                  </form>
                </div>
              </div>
              <div class="login-bottom-copyright">
                <span>© {{ new Date().getFullYear() }} Doccure. All rights reserved.</span>
              </div>
            </div>
          </div>
        </div>
        <!-- /Register Content -->
      </div>
    </div>
    <!-- /Page Content -->
</template>
<script>
export default {
  data() {
    return {
      password: '',
      confirmPassword: '',
      passwordError: '',
      passwordMismatchError: false,
      passwordStrength: '',
      passwordStrengthMessage: '',
      passwordStrengthPercent: 0,
    };
  },
  methods: {
    validateForm() {
      let isValid = true;

      // Validate Password
      this.checkPasswordStrength();
      if (!this.password) {
        this.passwordError = 'Password is required.';
        isValid = false;
      } else if (this.password.length < 6) {
        this.passwordError = 'Password must be at least 6 characters.';
        isValid = false;
      } else if (this.passwordStrengthPercent < 60) {
        this.passwordError = 'Password is too weak. Please use a stronger password.';
        isValid = false;
      } else {
        this.passwordError = ''; // Clear error if password is strong enough
      }
      // Check if passwords match
      this.passwordMismatchError = this.password !== this.confirmPassword;

      isValid =  !this.passwordError && !this.passwordMismatchError;

      return isValid;
    },
    checkPasswordStrength() {
      const password = this.password;
      let strength = 0;

      if (password.length > 7) strength += 1;
      if (/[A-Z]/.test(password)) strength += 1;
      if (/[a-z]/.test(password)) strength += 1;
      if (/[0-9]/.test(password)) strength += 1;
      if (/[\W_]/.test(password)) strength += 1;

      this.passwordStrength = this.getStrengthClass(strength);
      this.passwordStrengthMessage = this.getStrengthMessage(strength);
      this.passwordStrengthPercent = this.getStrengthPercent(strength);
    },

    getStrengthClass(strength) {
      switch (strength) {
        case 1:
        case 2:
          return 'bg-danger';
        case 3:
          return 'bg-warning';
        case 4:
        case 5:
          return 'bg-success';
        default:
          return '';
      }
    },

    getStrengthMessage(strength) {
      switch (strength) {
        case 1:
        case 2:
          return 'Weak';
        case 3:
          return 'Moderate';
        case 4:
        case 5:
          return 'Strong';
        default:
          return '';
      }
    },

    getStrengthPercent(strength) {
      switch (strength) {
        case 1:
        case 2:
          return 20;
        case 3:
          return 60;
        case 4:
        case 5:
          return 100;
        default:
          return 0;
      }
    },

    async submitForm() {
      if (this.validateForm()) {
        this.$router.push("/register-step-two");
      } else {
        console.error("error");
      }
    },

    resetForm() {
      this.password = '';
      this.confirmPassword = '';
    },

  },
};
</script>
<style scoped>

/* Styles for password strength meter */
.passwordStrengthMessageIsMeet {
  height: 100px !important;
}
.progress {
  height: 5px;
}
.progress-bar {
  transition: width 0.5s ease;
}
.bg-danger {
  background-color: #dc3545;
}
.bg-warning {
  background-color: #ffc107;
}
.bg-success {
  background-color: #28a745;
}
</style>