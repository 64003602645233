<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <!-- Page Content -->
    <div class="content top-space">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <!-- Account Content -->
            <div class="account-content">
              <div class="row align-items-center justify-content-center">
                <div class="col-md-7 col-lg-6 login-left">
                  <img
                    src="../../../../../assets/img/login-banner.png"
                    class="img-fluid"
                    alt="Login Banner"
                  />
                </div>
                <div class="col-md-12 col-lg-6 login-right">
                  <div class="login-header">
                    <h3>
                      Doctor Register
                      <router-link to="/register">Not a Doctor?</router-link>
                    </h3>
                  </div>

                  <!-- Register Form -->
                  <form @submit.prevent="submitForm">
                    <div class="mb-3 form-focus">
                      <input
                          type="text"
                          class="form-control floating"
                          v-model="name"
                          required
                      />
                      <label class="focus-label">Name</label>
                      <div v-if="nameError" class="text-danger">
                        {{ nameError }}
                      </div>
                    </div>
                    <div class="mb-3 form-focus">
                      <input
                          type="email"
                          class="form-control floating"
                          v-model="email"
                          required
                      />
                      <label class="focus-label">Email</label>
                      <div v-if="emailError" class="text-danger">
                        {{ emailError }}
                      </div>
                    </div>
                    <div class="mb-3 form-focus">
                      <input
                          type="text"
                          class="form-control floating"
                          v-model="mobile"
                      />
                      <label class="focus-label">Mobile Number (Optional)</label>
                      <div v-if="mobileError" class="text-danger">
                        {{ mobileError }}
                      </div>
                    </div>
                    <div class="mb-3 form-focus"
                         :class="{ 'passwordStrengthMessageIsMeet': passwordStrengthMessage }"
                    >
                      <input
                          type="password"
                          class="form-control floating"
                          v-model="password"
                          @input="checkPasswordStrength"
                          required
                      />
                      <label class="focus-label">Create Password</label>
                      <div v-if="passwordError" class="text-danger">{{ passwordError }}</div>
                      <div v-if="passwordStrengthMessage" class="text-info">{{ passwordStrengthMessage }}</div>
                      <div v-if="passwordStrength" class="progress mt-2">
                        <div
                            class="progress-bar"
                            :class="passwordStrength"
                            role="progressbar"
                            :style="{ width: passwordStrengthPercent + '%' }"
                        ></div>
                      </div>
                    </div>
                    <div class="mb-3 form-focus">
                      <input
                          type="password"
                          class="form-control floating"
                          v-model="confirmPassword"
                          required
                      />
                      <label class="focus-label">Confirm Password</label>
                      <div v-if="passwordMismatchError" class="text-danger">Passwords do not match.</div>
                    </div>
                    <div class="mb-3 form-focus">
                      <input
                          type="text"
                          class="form-control floating"
                          :value="providedId"
                          disabled
                      />
                      <label class="focus-label">Provided ID</label>
                      <div v-if="idError" class="text-danger">
                        {{ idError }}
                      </div>
                    </div>
                    <div class="text-end">
                      <router-link class="forgot-link" to="/login"
                      >Already have an account?</router-link
                      >
                    </div>
                    <b-button
                      class="btn btn-primary w-100 btn-lg login-btn"
                      type="submit"
                    >
                      Signup
                    </b-button>
                    <div class="login-or">
                      <span class="or-line"></span>
                      <span class="span-or">or</span>
                    </div>
                    <div class="row social-login">
                      <div class="col-6">
                        <a href="javascript:void(0)" class="btn btn-facebook w-100"
                          ><i class="fab fa-facebook-f me-1"></i> Login</a
                        >
                      </div>
                      <div class="col-6">
                        <a href="javascript:void(0)" class="btn btn-google w-100"
                          ><i class="fab fa-google me-1"></i> Login</a
                        >
                      </div>
                    </div>
                  </form>
                  <!-- /Register Form -->
                </div>
              </div>
            </div>
            <!-- /Account Content -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <indexfooter />
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      email: '',
      mobile: '',
      password: '',
      confirmPassword: '',
      providedId: '',
      nameError: '',
      emailError: '',
      mobileError: '',
      passwordError: '',
      passwordMismatchError: false,
      passwordStrength: '',
      passwordStrengthMessage: '',
      passwordStrengthPercent: 0,
      idError: '',
    };
  },
  created() {
    this.initializeIdRange();
    this.providedId = this.getNextUniqueId();
  },
  methods: {
    initializeIdRange() {
      if (!localStorage.getItem('nextId')) {
        localStorage.setItem('nextId', '100000');
        localStorage.setItem('usedIds', JSON.stringify([]));
      }
    },
    getNextUniqueId() {
      let nextId = parseInt(localStorage.getItem('nextId'));
      let usedIds = JSON.parse(localStorage.getItem('usedIds')) || [];

      // Find the next unused ID
      while (usedIds.includes(nextId)) {
        nextId += 1;
        if (nextId > 999999) nextId = 100000; // Loop back if the limit is exceeded
      }

      // Update the nextId in localStorage for the next allocation
      localStorage.setItem('nextId', (nextId + 1).toString());
      return nextId.toString().padStart(6, '0');
    },
    cancelRegistration() {
      // Add the current ID to the list of used IDs
      const usedIds = JSON.parse(localStorage.getItem('usedIds')) || [];
      usedIds.push(parseInt(this.providedId));
      localStorage.setItem('usedIds', JSON.stringify(usedIds));

      // Reset form fields
      this.resetForm();
      this.providedId = this.getNextUniqueId();
    },

    validateForm() {
      let isValid = true;

      // Validate Name
      if (!this.name) {
        this.nameError = 'Name is required.';
        isValid = false;
      } else {
        this.nameError = '';
      }

      // Validate Email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.email) {
        this.emailError = 'Email is required.';
        isValid = false;
      } else if (!emailRegex.test(this.email)) {
        this.emailError = 'Enter a valid email address.';
        isValid = false;
      } else {
        this.emailError = '';
      }

      // Validate Mobile Number (Optional but must be valid if filled)
      const mobileRegex = /^[0-9]{10}$/; // Example: Validating 10-digit mobile number
      if (this.mobile && !mobileRegex.test(this.mobile)) {
        this.mobileError = 'Enter a valid 10-digit mobile number.';
        isValid = false;
      } else {
        this.mobileError = '';
      }

      // Validate Password
      this.checkPasswordStrength();
      if (!this.password) {
        this.passwordError = 'Password is required.';
        isValid = false;
      } else if (this.password.length < 6) {
        this.passwordError = 'Password must be at least 6 characters.';
        isValid = false;
      } else if (this.passwordStrengthPercent < 60) {
        this.passwordError = 'Password is too weak. Please use a stronger password.';
        isValid = false;
      } else {
        this.passwordError = ''; // Clear error if password is strong enough
      }
      // Check if passwords match
      this.passwordMismatchError = this.password !== this.confirmPassword;

      isValid = !this.nameError && !this.emailError && !this.mobileError && !this.passwordError && !this.passwordMismatchError;

      return isValid;
    },
    checkPasswordStrength() {
      const password = this.password;
      let strength = 0;

      if (password.length > 7) strength += 1;
      if (/[A-Z]/.test(password)) strength += 1;
      if (/[a-z]/.test(password)) strength += 1;
      if (/[0-9]/.test(password)) strength += 1;
      if (/[\W_]/.test(password)) strength += 1;

      this.passwordStrength = this.getStrengthClass(strength);
      this.passwordStrengthMessage = this.getStrengthMessage(strength);
      this.passwordStrengthPercent = this.getStrengthPercent(strength);
    },

    getStrengthClass(strength) {
      switch (strength) {
        case 1:
        case 2:
          return 'bg-danger';
        case 3:
          return 'bg-warning';
        case 4:
        case 5:
          return 'bg-success';
        default:
          return '';
      }
    },

    getStrengthMessage(strength) {
      switch (strength) {
        case 1:
        case 2:
          return 'Weak';
        case 3:
          return 'Moderate';
        case 4:
        case 5:
          return 'Strong';
        default:
          return '';
      }
    },

    getStrengthPercent(strength) {
      switch (strength) {
        case 1:
        case 2:
          return 20;
        case 3:
          return 60;
        case 4:
        case 5:
          return 100;
        default:
          return 0;
      }
    },

    async submitForm() {
      if (this.validateForm()) {
        this.$router.push("/doctor-registeration-code");
      } else {
        console.error("error");
      }
    },

    resetForm() {
      this.name = '';
      this.email = '';
      this.mobile = '';
      this.password = '';
      this.confirmPassword = '';
    },

  },
};
</script>
<style scoped>

/* Styles for password strength meter */
.passwordStrengthMessageIsMeet {
  height: 100px !important;
}
.progress {
  height: 5px;
}
.progress-bar {
  transition: width 0.5s ease;
}
.bg-danger {
  background-color: #dc3545;
}
.bg-warning {
  background-color: #ffc107;
}
.bg-success {
  background-color: #28a745;
}
</style>
