import { loginUser, logoutUser } from '@/services/auth';

export default {
    namespaced: true,
    state() {
        return {
            isPasswordValidate: false,
            errorMessage: null,
        };
    },
    mutations: {
        setIsPasswordValidate(state, isPasswordValidate) {
            state.isPasswordValidate = isPasswordValidate;
        },
        setErrorMessage(state, errorMessage) {
            state.errorMessage = errorMessage;
        }
    },
    actions: {
        async isPasswordValidate({ commit }, { email, password }) {
            try {
                const response = await loginUser({ email, password });
                console.log("Response from loginUser:", response);
                if (response.token) {
                    commit('setIsPasswordValidate', true);
                    commit('setErrorMessage', null);
                    console.log("Password validation successful, mutation committed.");
                } else {
                    commit('setIsPasswordValidate', false);
                    commit('setErrorMessage', 'Password verification failed.');
                    console.log("Password validation failed, mutation committed.");
                }
                return response;
            } catch (error) {
                console.log("Error during login:", error);
                commit('setIsPasswordValidate', false);
                commit('setErrorMessage', 'An error occurred while verifying the password.');
                return { success: false, error };
            }
        },
        logout({ commit }) {
            commit('setIsPasswordValidate', false);

            try {
                const response = logoutUser();
                if (response.success) {
                    console.log('your are logged out!');
                }
            }catch (error) {
                console.log(error);
            }
        }
    },
    getters: {
        isPasswordValidate: (state) => state.isPasswordValidate,
        errorMessage: (state) => state.errorMessage,
    }
};
