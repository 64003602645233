<template>
  <div v-if="isOpen" class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop>
      <div class="modal-header">
        <h2>Menu</h2>
        <button class="close-button" @click="closeModal">&times;</button>
      </div>
      <div class="modal-body">
        <!-- Add your menu items here -->
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/patient-plans">Plans</router-link></li>
<!--          <li><router-link to="/services">Services</router-link></li>-->
<!--          <li><router-link to="/contact">Contact</router-link></li>-->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close'],
  methods: {
    closeModal() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  width: 100%;
  height: 100%;
  padding: 20px;
  position: relative;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.modal-body {
  margin-top: 20px;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body ul {
  list-style: none;
  padding: 0;
}

.modal-body ul li {
  margin-bottom: 10px;
}

.modal-body ul li a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
}
</style>