<template>
  <nav class="bottom-nav">
    <ul>

<!--      <li><router-link to="/dashboard">
        <i class="fa fa-home fa-fw"></i>
        <small>Home</small>
      </router-link></li>-->

<!--      <li><router-link to="/dashboard">
        <i class="fa fa-search"></i>
        <small>Search</small>
        </router-link></li>-->

<!--      <li @click="openMenu">-->

<!--      active-class="active"-->
      <li><router-link :to="{ name: 'patient-plans'}">
        <i class="fa-solid fa-clipboard-check"></i>
        <small>Plans</small>
      </router-link></li>

      <li style="position: relative"><router-link to="/cart">
        <i class="fa-solid fa-cart-shopping">
        <small v-if="totalItems > 0"
               class="unread-msg1 items-cart-count">
          {{ totalItems }}
        </small></i>
        <small>Cart</small>
      </router-link></li>

      <li><router-link to="/profile-settings">
        <i class="fa fa-user"></i>
        <small>Profile</small>
      </router-link></li>

    </ul>
    <!-- Menu Modal -->
    <mobile-menu-modal :isOpen="isMenuOpen" @close="closeMenu" />
  </nav>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useCart } from '@/composables/useCart';

export default {

  setup() {
    const isMenuOpen = ref(false);
    const { totalItems, fetchCart } = useCart();

    onMounted(async () => {
      await fetchCart(); // Ensure the cart is fetched when the component mounts
    });

    const openMenu = () => {
      isMenuOpen.value = true;
    };

    const closeMenu = () => {
      isMenuOpen.value = false;
    };

    // const { totalItems } = useCart();

    return {
      isMenuOpen,
      openMenu,
      closeMenu,
      totalItems,
    };
  },
};
</script>

<style scoped>
.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-top: 1px solid #ccc;
  z-index: 1000;
}

.bottom-nav ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding: 10px 0;
}
.bottom-nav ul li {
  cursor: pointer;
}
.bottom-nav ul li a {
  text-decoration: none;
  color: #333;
}
.bottom-nav i {
  display: block;
  font-size: 20px;
  //font-size: x-large;
  text-align: center;
  color: gray;
}
.bottom-nav ul li small {
  display: block;
  font-size: 12px;
  color: gray;
}
small.unread-msg1.items-cart-count {
  background-color: #074383;
}

.active.router-link-exact-active,
.active.router-link-exact-active i,
.active.router-link-exact-active small{
  color: black !important;
}
.active.router-link-exact-active small.unread-msg1.items-cart-count {
  background-color: #3f92e9;
}

</style>