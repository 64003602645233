<template>
  <div class="safety-warning">
    <p>
      <strong>Safety Warning:</strong> Please consult with a healthcare provider before making any changes to the prescribed dosages.
    </p>
  </div>
</template>

<style scoped>
.safety-warning {
  background-color: #f8d7da; /* Light red background for warning */
  border: 1px solid #f5c6cb; /* Red border */
  border-radius: 4px; /* Rounded corners */
  padding: 10px;
  margin: 20px 0;
  color: #721c24; /* Dark red text color */
  font-size: 16px;
  font-weight: bold;
}
</style>