<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1"/>
    <div class="content doctor-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-xl-3 theiaStickySidebar">
            <profilesidebar></profilesidebar>
          </div>

          <div class="col-lg-8 col-xl-9">
            <div class="dashboard-header">
              <h3>Your Plans</h3>
              <ul class="header-list-btns">
                <li>
                  <div class="input-block dash-search-input">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Search"
                    />
                    <span class="search-icon"><i class="fa-solid fa-magnifying-glass"></i></span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="appointment-tab-head plans-timing-tabs">
              <div class="appointment-tabs">
                <ul class="nav nav-pills inner-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                        class="nav-link"
                        :class="{ active: activeTab === 'morning' }"
                        @click="setActiveTab('morning')"
                        type="button"
                    >
                      Morning<span>{{ planCounts.morning }}</span>
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                        class="nav-link"
                        :class="{ active: activeTab === 'noon' }"
                        @click="setActiveTab('noon')"
                        type="button"
                    >
                      Noon<span>{{ planCounts.noon }}</span>
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                        class="nav-link"
                        :class="{ active: activeTab === 'evening' }"
                        @click="setActiveTab('evening')"
                        type="button"
                    >
                      Evening<span>{{ planCounts.evening }}</span>
                    </button>
                  </li>
                </ul>
              </div>
              <appointments-filter></appointments-filter>
            </div>

            <div class="tab-content appointment-tab-content">
              <plan-tab
                  v-for="type in ['morning', 'noon', 'evening']"
                  :key="type"
                  :isActive="activeTab === type"
                  :plans="plansByType[type]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <indexfooter></indexfooter>
    <bottom-navbar/>
  </div>
</template>

<script>
import { computed, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

export default {
  data() {
    return {
      title: "Plans",
      text: "Home",
      text1: "Plans"
    };
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    // Reactive variable for active tab
    const activeTab = ref(route.query.tab || 'morning'); // Default to 'morning' if no query


    // Get plans from Vuex store
    const plans = computed(() => store.getters['plans/plans']);

    // Fetch plans when the component is mounted
    onMounted(() => {
      if (plans.value.length === 0) {
        store.dispatch('plans/fetchPlans');
      }
    });

    // Count plans based on time
    const planCounts = computed(() => ({
      morning: plans.value.filter(plan => plan.plan_time.toLowerCase() === 'morning').length,
      noon: plans.value.filter(plan => plan.plan_time.toLowerCase() === 'noon').length,
      evening: plans.value.filter(plan => plan.plan_time.toLowerCase() === 'evening').length
    }));

    // Organize plans by type
    const plansByType = computed(() => {
      return {
        morning: plans.value.filter(plan => plan.plan_time.toLowerCase() === 'morning'),
        noon: plans.value.filter(plan => plan.plan_time.toLowerCase() === 'noon'),
        evening: plans.value.filter(plan => plan.plan_time.toLowerCase() === 'evening')
      };
    });

    // Set active tab and update URL
    const setActiveTab = (tab) => {
      activeTab.value = tab;
      router.push({query: {tab}});
    };

    return {
      activeTab,
      setActiveTab,
      planCounts,
      plansByType
    };
  }
};
</script>
