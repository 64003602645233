<template>
  <div class="main-wrapper">

    <layoutheader></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1" />
    <loading-spinner v-if="isLoading" />
    <div v-else class="content doctor-content">
      <div class="container">
        <div class="row">
          <!-- Profile Sidebar -->
          <div class="col-lg-4 col-xl-3 theiaStickySidebar">
            <profilesidebar></profilesidebar>
          </div>
          <!-- / Profile Sidebar -->

          <div class="col-lg-8 col-xl-9 mb-5">
            <div class="dashboard-header">
              <h3>Shopping Cart</h3>
              <ul class="header-list-btns">
                <li>
                  <div class="input-block dash-search-input">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Search"
                    />
                    <span class="search-icon">
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </span>
                  </div>
                </li>
              </ul>
            </div>

            <div v-if="cart.length" class="account-details-box">
              <!-- Cart Summary (Statistics) -->
              <div class="row">
                <div class="col-xxl-6 col-lg-7">
                  <div class="account-payment-info">
                    <h4>Cart Summary</h4>
                    <div class="row">
                      <div class="col-lg-5 col-md-6">
                        <div class="payment-amount">
                          <h6><i class="fa-solid fa-file-invoice-dollar text-success"></i>Total Items</h6>
                          <span>{{ totalItems }}</span>
                        </div>
                      </div>
                      <div class="col-lg-5 col-md-6">
                        <div class="payment-amount">
                          <h6><i class="fa-solid fa-dollar-sign text-pink"></i>Total Price</h6>
<!--                          <span>\€{{ totalPrice }}</span>-->
                          <span>€{{ totalPriceFormatted }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="payment-request">
                      <router-link class="apt-btn btn btn-primary prime-btn" to="/product-checkout"
                      >Proceed to checkout</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Cart Items -->
            <div v-if="cart.length" class="dependent-wrap" v-for="(item, index) in cart" :key="item.id">
              <div class="dependent-info">
                <div class="patinet-information">
                  <router-link to="/patient-profile">
                    <img :src="item.image" alt="Product Image" />
<!--                    <img src="@/assets/img/supplements/supplement-1.jpg" alt="Product Image" />-->
                  </router-link>
                  <div class="patient-info">
                    <h5>{{ item.name }}</h5>
                    <ul>
<!--                      <li>Category: {{ item.category }}</li>-->
<!--                      <li>Price: \€{{ item.price }}</li>-->
                      <li>Price: €{{ item.price }}</li>
                    </ul>
                  </div>
                </div>
                <div class="blood-info">
                  <p>Quantity</p>
                  <div class="custom-increment cart">
                    <div class="input-group1">
                      <span class="input-group-btn">
                        <b-button
                            type="button"
                            class="quantity-left-minus btn btn-danger btn-number"
                            @click="updateQuantity(item.package_id, item.quantity - 1)"
                            :disabled="item.quantity === 1"
                        >
                          <span><i class="fas fa-minus"></i></span>
                        </b-button>
                      </span>
                      <input
                          type="text"
                          class="input-number"
                          :value="item.quantity"
                          readonly
                      />
                      <span class="input-group-btn">
                        <b-button
                            type="button"
                            class="quantity-right-plus btn btn-success btn-number"
                            @click="updateQuantity(item.package_id, item.quantity + 1)"
                        >
                          <span><i class="fas fa-plus"></i></span>
                        </b-button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="blood-info">
                <p>SubTotal</p>
                <div class="custom-increment cart">
                  <div class="input-group1">
                      <span class="input-group-btn">
                        €{{ (item.quantity * item.price).toFixed(2) }}
                      </span>
                  </div>
                </div>
              </div>
              <div class="table-action">
                <a href="javascript:void(0);"
                   @click="removeItemFromCart(item.package_id)"
                    class="btn btn-sm bg-danger-light">
                  <i class="fas fa-times"></i>
                </a>
              </div>
            </div>

            <div v-else class="mb-5">
              <p><strong>Your cart is empty!</strong></p>
            </div>

            <related-items-2-cart @item-added="refreshCart"></related-items-2-cart>

          </div>
        </div>
      </div>
    </div>
    <indexfooter></indexfooter>
    <bottom-navbar />
  </div>
</template>

<script setup>
import { useCart } from '@/composables/useCart';
import {ref, onMounted, computed, watch} from "vue";

const title = ref("Cart");
const text = ref("Home");
const text1 = ref("Cart");
const isLoading = ref(true);

const { cart, updateItemQuantity, removeItem, totalItems, totalPrice, toggleItemStatus, fetchCart } = useCart();

onMounted(async () => {
  isLoading.value = true;
  await fetchCart();
  isLoading.value = false;

});

function refreshCart() {
  isLoading.value = true;
  fetchCart()
      .then((newCart) => {
        console.log('Fetched new cart:', newCart);
        cart.value = [...newCart];
        isLoading.value = false;
      })
      .catch(() => {
        isLoading.value = false;
        console.error('Failed to fetch cart');
      });
}

watch(cart, (newCart) => {
  console.log('Cart updated:', newCart);
});
// Ensure total price calculation only happens when cart items are valid
const totalPriceFormatted = computed(() => {
  if (cart.length) {
    const total = cart.reduce((acc, item) => acc + item.quantity * item.price, 0);
    return total.toFixed(2); // Format to two decimal places
  }
  return "0.00"; // Return 0 if cart is empty or undefined
});

function updateQuantity(productId, quantity) {
  // Prevent quantity from going below 1
  if (quantity < 1) return;

  // Call the composable method to update the item quantity
  updateItemQuantity(productId, quantity);
}

function removeItemFromCart(productId){
 removeItem(productId);
}

</script>
<style scoped>
/* Latest Blog Posts  */
.our-blog-section.eye-blog{
  background: transparent;
}
/* End of Latest Blog Posts  */
</style>