import { createApp } from 'vue'
import { router } from './router';
import App from './App.vue'
import store from './store';
import {BootstrapVue3, BToastPlugin} from 'bootstrap-vue-3'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import VueSelect from 'vue3-select2-component'
import VCalendar from 'v-calendar';
import VueFeather from 'vue-feather';
import DatePicker from 'vue3-datepicker';
import VueApexCharts from "vue3-apexcharts";
import Vue3Autocounter from 'vue3-autocounter';
import VueTelInput from 'vue3-tel-input';
import VueEasyLightbox from "vue-easy-lightbox";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap'; // Import Bootstrap JS


// plugins
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'vue3-tel-input/dist/vue3-tel-input.css';

import VueSweetalert2 from './plugins/vue-sweetalert2';


// Import the global stylesheet
import './assets/global.css';

/***********************************************************************/
                     /* Frontend */
/***********************************************************************/

//Breadcrumb
import BlogSidebar from '@/views/frontend/layouts/blogsidebar.vue'
import Mainmenu from '@/components/frontend/mainmenu.vue'
import Mainnav from '@/components/frontend/mainnav.vue'
import BottomNavbar from '@/components/mobile/BottomNav.vue';
import MobilMenuModal from '@/components/mobile/MenuModal.vue';

import Breadcrumb from '@/components/frontend/breadcrumb/aboutus.vue'
import Breadcrumb1 from '@/components/frontend/breadcrumb/breadcrumb1.vue'

import Profilewidget from '@/components/frontend/profilewidget.vue'
import DoctorFooter from '@/views/frontend/layouts/doctor-footer.vue';
import DoctorSidebar from '@/views/frontend/layouts/doctorsidebar.vue';

//pages

import Header from '@/views/frontend/layouts/header.vue'
import HeaderTwo from  '@/views/frontend/layouts/headertwo.vue'
import Headerinfo from '@/views/frontend/layouts/header-info.vue'
import Cursor from '@/views/frontend/layouts/cursor.vue'
import Scrolltotop from '@/views/frontend/layouts/scrolltotop.vue'
import ProfileSidebar from '@/views/frontend/layouts/profilesidebar.vue'

import Footer from '@/views/frontend/layouts/footer.vue'
import IndexBanner from '@/views/frontend/pages/home/indexbanner.vue'
import IndexDoctor from '@/views/frontend/pages/home/indexdoctor.vue'
import IndexWork from '@/views/frontend/pages/home/indexwork.vue'
import IndexApp from '@/views/frontend/pages/home/indexapp.vue'
import IndexTestimonial from '@/views/frontend/pages/home/indextestimonial.vue'
import IndexPartners from '@/views/frontend/pages/home/indexpartners.vue'
import IndexFooter from '@/views/frontend/pages/home/footer.vue'

// patients
import PatientPlansMorning from '@/views/frontend/pages/patients/plans/patient-plans-morning.vue';
import PatientPlansLunch from '@/views/frontend/pages/patients/plans/patient-plans-lunch.vue';
import PatientPlansEvening from '@/views/frontend/pages/patients/plans/patient-plans-evening.vue';
import PlanTab from '@/views/frontend/pages/patients/plans/plan-tab.vue';

// blog
import LatestBlogPosts from '@/components/frontend/blogs/LatestBlogPosts.vue';

// items
import RelatedItems2Cart from '@/components/frontend/items/RelatedItems2Cart.vue';


//Auth
import PasswordConfirmationModal from '@/components/frontend/authentication/PasswordConfirmationModal.vue'

// general components
import SafetyWarning from '@/components/SafetyWarning.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

/***********************************************************************/
                      /*  Pharmacy */
/***********************************************************************/

const app = createApp(App);

// Check if token exists and validate it
const token = localStorage.getItem('token');
if (token) {
    // Optionally, validate token with API here
    store.dispatch('setAuthToken', token);
    store.dispatch('setIsAuthenticated', true);
}

app.use(router);
app.use(store);


// Breadcrumb
app.component('breadcrumb',Breadcrumb)

app.component('breadcrumb1',Breadcrumb1)
app.component('blogsidebar',BlogSidebar)
app.component('mainmenu',Mainmenu)
app.component('mainnav',Mainnav)
app.component('bottom-navbar',BottomNavbar)
app.component('mobile-menu-modal',MobilMenuModal)
app.component('profilewidget',Profilewidget)
app.component('doctor-footer', DoctorFooter)
app.component('doctorsidebar', DoctorSidebar)


//pages
app.component('layoutheader', Header)
app.component('layoutheadertwo',HeaderTwo)
app.component('headerinfo',Headerinfo)
app.component('cursor',Cursor)
app.component('scrolltotop',Scrolltotop)
app.component('footerindex',Footer)
app.component('indexbanner',IndexBanner)
app.component('indexdoctor',IndexDoctor)
app.component('indexwork', IndexWork)
app.component('indexapp', IndexApp)
app.component('indextestimonial',IndexTestimonial)
app.component('indexpartners',IndexPartners)
app.component('indexfooter',IndexFooter)


//Patient pages
app.component('profilesidebar',ProfileSidebar)
app.component('patient-plans-morning',PatientPlansMorning)
app.component('patient-plans-lunch',PatientPlansLunch)
app.component('patient-plans-evening',PatientPlansEvening)
app.component('plan-tab',PlanTab)

// Blog
app.component('latest-blog-posts', LatestBlogPosts);

// Items
app.component('related-items-2-cart', RelatedItems2Cart);

// Authentication
app.component('password-confirmation-modal', PasswordConfirmationModal);

// General
app.component('safety-warning', SafetyWarning);
app.component('loading-spinner', LoadingSpinner);

// Modal
app.component('vue3-autocounter', Vue3Autocounter)
app.component('vue-select', VueSelect)
app.component(VueFeather.name, VueFeather)
app.component('datepicker', DatePicker)
app.use(VueApexCharts)
app.use(VueEasyLightbox)
.use(VueTelInput)
.use(BootstrapVue3)
.use(BToastPlugin)
.use(Antd)
app.use(VCalendar, {})

app.use(VueSweetalert2);

app.mount('#app');
