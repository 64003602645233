<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1" />
    <div class="content">
      <div class="container">
        <div class="row">
          <!-- Profile Sidebar -->
          <div class="col-lg-4 col-xl-3 theiaStickySidebar">
            <!-- Profile Sidebar -->
            <profilesidebar></profilesidebar>
            <!-- /Profile Sidebar -->
          </div>
          <!-- / Profile Sidebar -->

          <div class="col-lg-8 col-xl-9">
            <div class="dashboard-header">
              <h3>Dashboard</h3>
            </div>
            <div class="row">
              <div v-if="successMessage" class="alert alert-success ml-3">
                {{ successMessage }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <doctor-footer></doctor-footer>
  </div>
  <patient-dashboard-modal></patient-dashboard-modal>

  <bottom-navbar />

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      title: "Patient Dashboard",
      text: "Home",
      text1: "Patient Dashboard",
    };
  },
  computed: {
    ...mapGetters(['successMessage']),
  },
};
</script>
<style scoped>
/* styles for the success message */
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  border-radius: 0;
}
</style>