import axiosInstance from './axios';


// List of Plans
export const getPlans = async () => {
    try {
        const response = await axiosInstance.get('api/plan/list');
        console.log('response.data: ', response.data.data[0]);
        return response.data.data[0].items;
    } catch (error) {
        console.error('Error resetting password:', error);
        throw error;
    }
};

// Fetch a single plan item by ID
export const getPlanById = async (id) => {
    try {
        const response = await axiosInstance.get(`api/plan/item/${id}`);
        console.log('Fetched plan itemm:', response.data);
        return response.data.data; // Adjust based on the API response structure
    } catch (error) {
        console.error('Error fetching plan item:', error);
        throw error;
    }
};

