<template>
  <div
      class="tab-pane fade show active"
      id="pills-upcoming"
      role="tabpanel"
      aria-labelledby="pills-upcoming-tab"
  >
    <!-- Plans List -->
    <div
        class="appointment-wrap"
        v-for="item in plans"
        :key="item.id"
    >
      <ul>
        <li>
          <div class="patinet-information">
            <router-link :to="{ name: 'patient-morning-plan', params: { id: item.item_id } }">
              <img
                  :src="item.image"
                  alt="User Image"
              />
            </router-link>
            <div class="patient-info">
              <!--              <p>{{ item.Id }}</p>-->
              <ul>
                <li>
                  <h6>
                    <router-link :to="{ name: 'patient-morning-plan', params: { id: item.item_id } }">
                      {{ item.name }}
                    </router-link>
                  </h6>
                </li>
                <li>
                  <span class="badge new-tag">{{ item.custom_dosage }} - {{ item.custom_instructions }}</span>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!--        <li class="appointment-info">
                  <p><i class="fa-solid fa-clock"></i> Start Date</p>
                  <ul>
                    <li>{{ item.StartDate }}</li>
                  </ul>
                </li>
                <li class="appointment-info">
                  <p><i class="fa-solid fa-clock"></i> End Date</p>
                  <ul>
                    <li>{{ item.EndDate }}</li>
                  </ul>
                </li>-->
        <!--        <li class="appointment-detail-btn">
                  <a href="#" class="start-link"
                  ><i class="fa-solid fa-calendar-check me-1"></i>Attend</a
                  >
                </li>-->
      </ul>
    </div>
    <!-- /Plans List -->

    <!-- Pagination -->
<!--    <div class="pagination dashboard-pagination">
      <ul>
        <li>
          <a href="#" class="page-link"
          ><i class="fa-solid fa-chevron-left"></i
          ></a>
        </li>
        <li>
          <a href="#" class="page-link">1</a>
        </li>
        <li>
          <a href="#" class="page-link active">2</a>
        </li>
        <li>
          <a href="#" class="page-link">3</a>
        </li>
        <li>
          <a href="#" class="page-link">4</a>
        </li>
        <li>
          <a href="#" class="page-link">...</a>
        </li>
        <li>
          <a href="#" class="page-link"
          ><i class="fa-solid fa-chevron-right"></i
          ></a>
        </li>
      </ul>
    </div>-->
    <!-- /Pagination -->
  </div>
</template>
<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
// import {getPlans} from "@/services/plans";

export default {
  setup() {
    const store = useStore();

    // Fetch plans when the component is mounted
    onMounted(() => {
      store.dispatch('plans/fetchPlans');
    });

    const plans = computed(() => store.getters['plans/plans']);

    return {
      plans
    };
  }
};
</script>