<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1" />
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-xl-3 theiaStickySidebar">
            <!-- Profile Sidebar -->
            <profilesidebar></profilesidebar>
            <!-- /Profile Sidebar -->
          </div>

          <div class="col-lg-8 col-xl-9">

            <form @submit.prevent="submitForm">

              <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>
              <div v-if="successMessage" class="alert alert-success mt-3">{{ successMessage }}</div>

              <div @click="toggleSection('information')"
                  class="collapsible-section-item setting-title">
                <h5>Your Information</h5>
                <i :class="isOpen.information ? 'fa fa-chevron-up rotate' : 'fa fa-chevron-down'" class="toggle-icon"></i>
              </div>
              <div class="disclaimer">
                <p>
                  <strong>
                    To change your account information please contact us directly.
                  </strong>
                </p>
              </div>
              <div v-if="isOpen.information"  class="setting-card">
                <div class="row">
                  <div class="col-lg-4 col-md-6">
                    <div class="form-wrap">
                      <label class="col-form-label"
                        >Name <span class="text-danger">*</span></label
                      >
                      <input type="text" class="form-control"
                             v-model="userData.name" readonly />
                    </div>
                  </div>
<!--                  <div class="col-lg-4 col-md-6">
                    <div class="form-wrap">
                      <label class="col-form-label"
                        >Last Name <span class="text-danger">*</span></label
                      >
                      <input type="text" class="form-control"
                             />
                    </div>
                  </div>-->
                  <div class="col-lg-4 col-md-6">
                    <div class="form-wrap">
                      <label class="col-form-label"
                        >Email Address <span class="text-danger">*</span></label
                      >
                      <input type="email" class="form-control"
                             v-model="userData.email" readonly  />
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-btn text-end">
<!--                <a href="#" class="btn btn-gray">Cancel</a>-->
<!--                <button type="submit" class="btn btn-primary prime-btn" disabled>
                  Save Changes
                </button>-->
              </div>
            </form>
            <hr />
            <ul class="setting-links">
              <!--     Change Password         -->
              <li>
<!--                <button @click="showModal = true" class="btn btn-primary prime-btn">-->
                <button @click="redirectToChangePassword" class="btn btn-primary prime-btn">
                  <span>Change Password</span>
                </button>
              </li>
<!--              <password-confirmation-modal
                  :isVisible="showModal"
                  :redirectRoute="'/change-password'"
                  @close="showModal = false"
                  @confirmed="redirectToChangePassword"
                  @logout="logout"
              />-->
              <li>

                <!--     My Orders         -->
                <router-link to="/orders" class="btn btn-primary prime-btn">
                  <span>My Orders</span>
                </router-link>
              </li>
            </ul>
            <hr />
            <div class="modal-btn mb-5">
              <button @click="logout" type="submit" class="btn btn-danger">
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <indexfooter></indexfooter>

  <bottom-navbar />

</template>
<script>
import { ref, computed, onMounted } from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {registerUser} from "@/services/auth";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const userData = ref({
      name: '',
      email: ''
    });

    const showModal = ref(false);

    const errorMessage = computed(() => store.getters.errorMessage);
    const successMessage = computed(() => store.getters.successMessage);
    const registeredUser = computed(() => store.getters.registeredUser);

    onMounted(() => {
      // Get user data from localStorage as a string
      const storedUserData = localStorage.getItem('userData');

      // Check if storedUserData exists and is not null
      if (storedUserData) {
        // Parse the string into an object
        const parsedUserData = JSON.parse(storedUserData);

        // Update the userData reactive reference with the parsed object
        userData.value = parsedUserData;

        store.dispatch('setRegisteredUser', userData.value);

      } else {
        console.warn('No userData found in localStorage.');
      }
    });

    const redirectToChangePassword = async () => {
      try {
        const userData = registeredUser.value;
        console.log('store.getters.registeredUser:', userData); // Debugging

        // Check if userData is valid
        if (!userData || !userData.email || !userData.identifier) {
          console.error('Invalid user data:', userData);
          // Optionally, handle this case (e.g., show an error message to the user)
          return;
        }

        console.log('store.getters.registeredUser:', userData); // Debugging

        showModal.value = false;

        // Send request for verification code
        await registerUser({
          email: userData.email,
          identifier: userData.identifier
        });
        store.dispatch('setUserEmail', userData.email);

        // Redirect to the desired page
        router.push('/reset-password');
      } catch (error) {
        console.error('Error sending verification request:', error);
        // Handle any errors here (e.g., show a notification to the user)
      }
    };

    return {
      title: "Profile Settings",
      text: "Home",
      text1: "Profile Settings",
      isOpen: {
        information: true,
      },
      errorMessage,
      successMessage,
      showModal,
      redirectToChangePassword,
      userData
    };
  },
  methods: {
    toggleSection(section) {
      this.isOpen[section] = !this.isOpen[section];
    },
    submitForm() {
      this.$router.push("/profile-settings");
    },
/*    redirectToChangePassword() {
      this.showModal = false;
      this.$router.push('/change-password');
    },*/
    logout() {
      this.$store.dispatch('authentication/logout');
      this.$router.push('/login');
    }
  },
};
</script>
<style scoped>
.setting-links{
  display: flex;
  align-items: center;
  margin-top: 1%;
  gap: 4%;
}
.setting-title {margin-bottom: 3%}
.collapsible-section-item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1%;
}
.toggle-icon {
  font-size: 16px;
  transition: transform 0.6s;
}

.rotate {
  transform: rotate(0deg);
}
/* Disclaimer */
.disclaimer {
  padding: 15px;
  margin: 20px 0;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.disclaimer p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.disclaimer strong {
  font-weight: bold;
  color: green;
}
/* End of Disclaimer */

</style>
