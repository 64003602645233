<template>
    <layoutheader />

    <indexbanner />

     <cursor />
    <footerindex />

    <scrolltotop />
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
}
</script>
