<template>
  <!-- Page Content -->
  <div class="content login-page pt-0">
    <div class="container-fluid">
      <!-- Register Content -->
      <div class="account-content">
        <div class="row align-items-center">
          <div class="login-right">
            <div class="inner-right-login">
              <div class="login-header">
                <div class="logo-icon">
                  <img src="@/assets/img/logo.svg" alt="doccure-logo" />
                </div>
                <div class="step-list">
                  <ul>
                    <li><a href="javascript:void(0)" class="active-done" >1</a></li>
                    <li><a href="javascript:void(0)"class="active">2</a></li>
                    <li><a href="javascript:void(0)">3</a></li>
                    <li><a href="javascript:void(0)">4</a></li>
                  </ul>
                </div>
                <form id="profile_pic_form" enctype="multipart/form-data">
                  <div class="profile-pic-col">
                    <h3>Profile Picture</h3>
                    <div class="profile-pic-upload">
                      <div class="cam-col">
                        <img
                            src="@/assets/img/icons/camera.svg"
                            id="prof-avatar"
                            alt="camera-image"
                            class="img-fluid"
                        />
                      </div>
                      <span>Upload Profile Picture</span>
                      <input type="file" id="profile_image" name="profile_image" />
                    </div>
                  </div>
                  <div class="mt-5">
                    <router-link
                        @click="redirectReloadGrid"
                        to="/doctor-register-step2"
                        class="btn btn-primary w-100 btn-lg login-btn step1_submit"
                    >continue
                    </router-link>
                  </div>
                </form>
              </div>
            </div>
            <div class="login-bottom-copyright">
              <span>© {{ new Date().getFullYear() }} Doccure. All rights reserved.</span>
            </div>
          </div>
        </div>
      </div>
      <!-- /Register Content -->
    </div>
  </div>
  <!-- /Page Content -->
  <doctor-register-step2></doctor-register-step2>

</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    redirectReloadGrid() {
      this.$router.push({ path: "/doctor-register-step2" }).then(() => {
        this.$router.go();
      });
    },
  },
};
</script>