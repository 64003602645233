<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1 :title="title" :text="text" :text1="text1" />

    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <!-- Profile Sidebar -->
          <div class="col-lg-4 col-xl-3 theiaStickySidebar">
            <profilesidebar></profilesidebar>
          </div>
          <!-- / Profile Sidebar -->
          <div class="col-lg-8 col-xl-9">
            <div class="dashboard-header">
              <div class="header-back">
<!--                <router-link v-if="routerLinkTo" :to="routerLinkTo"-->
<!--                             class="back-arrow">-->
<!--                  <i class="fa-solid fa-arrow-left"></i>-->
<!--                </router-link>-->
                <h3>Order History </h3>
              </div>
            </div>
            <div class="appointment-details-wrap">
              <div v-for="order in filteredOrders" :key="order.order_id" class="appointment-wrap">
                <ul>
                  <li>
                    <div class="patinet-information">
<!--                      <router-link to="/doctor-cancelled-appointment">
                        <img src="@/assets/img/doctors-dashboard/profile-01.jpg" alt="User Image">
                      </router-link>-->
                      <div class="patient-info">
                        <p>Order ID: {{ order.order_id }} from {{  new Date(order?.created_at).toLocaleDateString()  }}</p>
<!--                        <h6><router-link to="/doctor-cancelled-appointment">Adrian</router-link></h6>-->
                      </div>
                    </div>
                  </li>
<!--                  <li class="appointment-info">
                    <ul class="d-flex apponitment-types">
                      <li>Order Date</li>
                    </ul>
                    <p><i class="fa-solid fa-clock"></i>{{  new Date(order?.created_at).toLocaleDateString()  }}</p>

                  </li>-->
                  <li class="appointment-info orders-packages">
                    <ul class="d-flex apponitment-types">
                      <li>Total Amount</li>
                      <li>€{{order.pakcages_sum_price}}</li>
                    </ul>
<!--                    <p><i class="fa-solid fa-daller"></i>€{{order.pakcages_sum_price}}</p>-->

                  </li>
                  <hr />

                  <li v-for="orderPackage in order.packages" :key="orderPackage.package_id" class="appointment-info orders-packages">
                    <ul class="d-flex apponitment-types">
                      <li>
                        <img :src="orderPackage.image" alt="User Image">
                      </li>
                      <li>{{orderPackage.item_name}}</li>
<!--                      <li>{{orderPackage.count}}</li>-->
                      <li>€{{orderPackage.total_price}}</li>
                    </ul>
                  </li>
<!--                  <li class="appointment-info orders-packages">
                    <ul class="d-flex apponitment-types">
                      <li>Shipping</li>
                      <li>€0.00</li>
                    </ul>
                  </li>-->
<!--                  <li class="appointment-detail-btn">
                    <router-link to="/orders" class="start-link">View Details</router-link>
                  </li>-->
                </ul>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <doctor-footer></doctor-footer>

    <bottom-navbar />

  </div>
</template>
<script setup>
import {ref, onMounted} from 'vue';
import {getOrders} from '@/services/order';

const title = ref("Orders");
const text = ref("Home");
const text1 = ref("Orders");

const orders = ref([]);
const filteredOrders = ref([]);

onMounted(async () => {
  try {
    const response = await getOrders(1,10);
    orders.value = response.data.data;
console.log('orders.value;;', orders.value)
    filteredOrders.value = orders.value
        .filter(order => order.status === 'done')
        .sort((a, b) => Number(b.order_id) - Number(a.order_id));

    console.log('filteredOrders.value;;', filteredOrders.value)

  } catch (error){
    console.log('Failed to fetch orders: ', error);
  }
})

</script>

<style scoped>
.orders-packages ul{
  display: flex !important;
  justify-content: space-between !important;
}
.orders-packages ul li {
  border-left: 1px none !important;
}
.orders-packages img {
  width: 100px;
}
hr{
  border: 1px solid black;
  opacity: 100%;
}
</style>