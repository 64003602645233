<template>
  <!-- Blog Section -->
  <section class="our-blog-section eye-blog">
    <div class="container">
      <div class="row">
        <div class="col-md-12 aos" data-aos="fade-up">
          <div class="section-heading text-center sec-heading-eye">
            <h2><span>Your</span> Prescriptions</h2>
          </div>
        </div>
      </div>
      <div class="owl-carousel eye-blogslider owl-them aos" data-aos="fade-up">
        <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
          <Slide v-for="item in LatestBlogPosts" :key="item.id">
            <div class="item">
              <div class="our-blogs">
                <div class="blogs-img">
                  <router-link to="#"><img :src="item.image" alt="Related Item" class="img-fluid blog-inner-img latest-blog-img">
                  </router-link>
                  <div class="blogs-overlay">
                    <span class="blog-cat">{{item.packages[0].size}}</span>
                  </div>
                </div>
                <div class="blogs-info">
                  <h4><router-link to="#">{{item.name}}</router-link></h4>
                  <h6>Price: <span class="blog-cat">€{{item.packages[0].price}}</span></h6>
                  <div class="start-btn">
                    <button @click="addToCart(item)"
                            class="btn btn-success add-to-cart-current-item">
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
          <template #addons>
            <Pagination />
          </template>
        </Carousel>
      </div>
    </div>
  </section>
  <!-- /Blog Section -->
</template>
<script setup>
import {ref, onMounted} from 'vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Carousel, Pagination, Navigation, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import {useCart} from "@/composables/useCart";
import { useStore } from 'vuex';

const store = useStore();
const LatestBlogPosts = ref([]);
const { addItem } = useCart();

const emit = defineEmits(['item-added']);

console.log('related item:', LatestBlogPosts.value)

// Carousel settings
const settings = ref({
  itemsToShow: 1,
  snapAlign: 'center',
});

const breakpoints = ref({
  575: {
    itemsToShow: 1,
    snapAlign: 'center',
  },
  767: {
    itemsToShow: 1,
    snapAlign: 'center',
  },
  991: {
    itemsToShow: 3,
    snapAlign: 'center',
  },
  1024: {
    itemsToShow: 4,
    snapAlign: 'start',
  },
});

onMounted(async () => {
  // Dispatch the Vuex action to fetch plans
  try {
    await store.dispatch('plans/fetchPlans');
    console.log('Plans fetched successfully');
  } catch (error) {
    console.error('Failed to fetch plans:', error);
  }

  // Get the latest blog posts from the Vuex store
  LatestBlogPosts.value = store.getters['plans/plans'];

  console.log('LatestBlogPosts after fetching:', LatestBlogPosts.value);

  AOS.init();

});

  async function addToCart(item) {
    const updatedItem = {
      ...item,
      quantity: 1,
      packageID: item.packages[0].id,
      price: item.packages[0].price };

    console.log('related item:', item)
    await addItem(updatedItem);
  console.log('item-added emit: ', updatedItem);
    emit('item-added', updatedItem);
  }
</script>
<style scoped>
img.img-fluid.blog-inner-img.latest-blog-img {
  height: 200px !important;
}
</style>